<template>
  <div class="">
    <div class="vx-col ml-3 flex-1 text-center my-4" v-if="checkTransactionId == '' && dtCheckResponse">
      <vs-icon v-if="dtCheckResponse.message_type != 1" icon="cancel" size="80px" color="rgba(var(--vs-danger), 1)" />
      <template v-else>
        <vs-icon v-if="dtCheckResponse.toast_color == 2" icon="info" size="80px" color="rgba(var(--vs-warning), 1)" />
        <vs-icon v-else icon="check_circle" size="80px" color="rgba(var(--vs-success), 1)" />
      </template>

      <div class="p-2"></div>
      <p>{{ dtCheckResponse.message }}</p>
      <div class="p-4"></div>
      <vs-button @click="closeStatusDialog">{{ $t('Close') }}</vs-button>
    </div>
    <div class="w-full mx-auto" v-if="dtCheckResponse == null">
      <h1 class="text-2xl font-bold flex justify-center">
        {{ $t('transactionCheck') }}
      </h1>

      <vs-input
        validate-on="change"
        :name="$t('TransactionID')"
        class="w-full" :label-placeholder="$t('TransactionID')"
        v-model="checkTransactionId"/>
      <div class="flex flex-row justify-center items-center space-x-4">
        <div class="flex justify-center" style="place-items: end;">
          <vs-button class="mt-5" @click="checkStatus">{{ $t('Check') }}</vs-button>
        </div>

        <div class="flex justify-center" style="place-items: end;">
          <vs-button class="mt-5" color="danger" @click="closeStatusDialog">{{ $t('Cancel') }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntlStatusCheck",
  data() {
    return {
      dtCheckResponse: null,
      checkTransactionId: '',
    };
  },
  methods: {
    checkStatusDialog() {
      this.checkTransactionId = '';
    },
    checkStatusCloseDialog() {
      this.checkTransactionId = '';
    },
    closeStatusDialog() {
      this.checkTransactionId = '';
      this.dtCheckResponse = null;
      this.$emit('close');
    },
    checkStatus() {
      if(!this.checkTransactionId) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please fill transaction id',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      }
      this.$vs.loading()
      this.$store.dispatch('internationalTopUp/checkStatus', this.checkTransactionId)
        .then((data) => {
          this.dtCheckResponse = data;
          this.$vs.loading.close()
          this.checkStatusCloseDialog();
        })
        .catch((error) => {
          this.dtCheckResponse = error.response.data;
          this.$vs.loading.close()
          this.checkStatusCloseDialog();
        })
    },
  }
}
</script>

<style scoped>

</style>
